import { Controller } from '@hotwired/stimulus'

const ExerciseBlockFormat = {
  SUPERSET: 1,
  CIRCUIT: 2,
}

export default class extends Controller {
  static targets = [
    'format',
    'rounds',
    'sets',
    'setsDisabled',
    'workingDuration',
    'restDuration',
    'totalDuration',
  ]

  connect() {
    this.showActiveFields()
  }

  formatChange() {
    this.showActiveFields()
  }

  showActiveFields() {
    const value = Number.parseInt(this.formatTarget.value, 10)
    if (
      value === ExerciseBlockFormat.CIRCUIT ||
      value === ExerciseBlockFormat.SUPERSET
    ) {
      this.roundsTarget.hidden = false
      this.restDurationTarget.hidden = false
      this.setsTarget.hidden = true
      this.setsDisabledTarget.hidden = false
    } else {
      this.setsTarget.hidden = false
      this.setsDisabledTarget.hidden = true
      this.roundsTarget.hidden = true
      this.restDurationTarget.hidden = true
    }
  }

  getWorkSecs() {
    const minsElement = this.workingDurationTarget.querySelector(
      "[name='working_duration_0']",
    )
    const secsElement = this.workingDurationTarget.querySelector(
      "[name='working_duration_1']",
    )

    const mins =
      minsElement.value === 'None'
        ? 0
        : Number.parseInt(minsElement.value, 10) || 0
    const secs =
      secsElement.value === 'None'
        ? 0
        : Number.parseInt(secsElement.value, 10) || 0

    return mins * 60 + secs
  }

  getRestSecs() {
    const minsElement = this.restDurationTarget.querySelector(
      "[name='rest_duration_0']",
    )
    const secsElement = this.restDurationTarget.querySelector(
      "[name='rest_duration_1']",
    )

    const mins =
      minsElement.value === 'None'
        ? 0
        : Number.parseInt(minsElement.value, 10) || 0
    const secs =
      secsElement.value === 'None'
        ? 0
        : Number.parseInt(secsElement.value, 10) || 0

    return mins * 60 + secs
  }

  formatTimeString(time) {
    const minutes = Math.floor(time / 60).toString()
    const seconds = Math.floor(time % 60)
    if (minutes > 0 && seconds > 0) {
      return `${minutes} minutes ${seconds} seconds`
    }
    if (minutes > 0) {
      return `${minutes} minutes`
    }
    return `${seconds} seconds`
  }

  calculateTotal() {
    const rounds = this.roundsTarget.querySelector('input').value
    const workSecs = this.getWorkSecs()
    const restSecs = this.getRestSecs()
    console.log(workSecs)
    console.log(restSecs)
    const totalDisplayOutput = this.totalDurationTarget.querySelector('p')
    if (!workSecs && !restSecs) {
      totalDisplayOutput.textContent = 'no timers set'
      return
    }

    const total = (this.getWorkSecs() + this.getRestSecs()) * rounds
    totalDisplayOutput.textContent = this.formatTimeString(total)
  }
}
