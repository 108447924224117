import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    const dataElement = document.getElementById(this.element.dataset.dataId)
    const data = JSON.parse(dataElement.textContent)
    const ctx = this.canvasTarget.getContext('2d')
    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: data.datasets[0].label,
            data: data.datasets[0].data,
            pointBackgroundColor: '#00A6EC',
            pointBorderColor: '#00A6EC',
            borderColor: '#00A6EC',
            backgroundColor: '#00A6EC',
            fill: 'False',
          },
          {
            label: data.datasets[1].label,
            data: data.datasets[1].data,
            pointBackgroundColor: '#00E584',
            pointBorderColor: '#00E584',
            borderColor: '#00E584',
            backgroundColor: '#00E584',
            fill: 'False',
          },
        ],
      },
      options: {
        scales: {
          r: {
            max: 100,
            min: 0,
          },
        },
        pointLabels: {
          color: '#06154F',
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              fontColor: '#06154F',
              fontSize: 14,
            },
          },
        },
      },
    })
  }
}
