import { Controller } from '@hotwired/stimulus'

/**
 * A wrapper for stelect and modal controller that connects both,
 * so that a new option for stelect can be added via modal and then
 * selected in the stelect.
 *
 * This controller expects data-controller="stelect" and data-controller="modal-trigger"
 * to be its children.
 *
 * Usage example:
 *
 * <div data-controller="stelect-add-option" class="flex items-end w-full">
 *
 * <div data-controller="stelect" data-stelect-expanded-value="false"
 *      data-stelect-empty-option-label-value="Select a movement" data-stelect-url-value="/autocomplete/movements/"
 *      id="stelect-exercise_set-0-movement">
 *   <select data-stelect-target="select" name="exercise_set-0-movement" class="w-full"
 *           id="id_exercise_set-0-movement" style="display: none;">
 *     <option value="2" selected="">Front Squat</option>
 *   </select>
 * </div>
 *
 * <div data-controller="modal-trigger" data-modal-allow-background-close="true">
 *   <button type="button" data-action="click->modal-trigger#open" class="bg-blue-500 hover:bg-blue-700">+</button>
 *
 *   <template data-modal-trigger-target="template">
 *     <div id="turbo-modal" data-controller="modal" data-modal-allow-background-close="true"
 *          data-modal-autodestroyable-value="true" data-modal-open-value="true">
 *       <div data-modal-target="container"
 *            data-action="click->modal#closeBackground keyup@window->modal#closeWithKeyboard"
 *            class="hidden animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center"
 *            style="z-index: 9999;">
 *         <div class="max-h-screen w-full max-w-lg relative">
 *           <div class="m-1 bg-white rounded shadow">
 *             <div class="p-8" data-modal-target="content">
 *               <turbo-frame id="add-movement" src="/organization/movements/add/" loading="lazy">
 *                 LOADING...
 *               </turbo-frame>
 *             </div>
 *           </div>
 *         </div>
 *       </div>
 *     </div>
 *   </template>
 * </div>
 *
 * </div>
 */
export default class extends Controller {
  get $stelectController() {
    return this.element.querySelector('[data-controller="stelect"]')
  }

  get $modalTemplate() {
    return this.element.querySelector('template')
  }

  connect() {
    this.validateChildren()
    onNextTick(this.setupController.bind(this))
  }

  /**
   * Checks required children controllers.
   */
  validateChildren() {
    const expectedControllers = [
      '[data-controller="stelect"]',
      '[data-controller="modal-trigger"]',
    ]
    expectedControllers.forEach((controllerSelector) => {
      if (!this.element.querySelector(controllerSelector)) {
        throw new Error(
          `${controllerSelector} is expected as a children of [data-controller="stelect-add-option"]`,
        )
      }
    })
  }

  setupController() {
    this.element.id = `${this.$stelectController.id}-add-controller`
    // Expose function on the element, so it can be called externally.
    this.element.selectOption = this.selectOption.bind(this)
    this.addStelectIdToTurboFrameSrc()
  }

  /**
   * Add provided option to the select and choose it.
   */
  selectOption(value, label) {
    const select = this.$stelectController.querySelector('select')
    const option = document.createElement('option')
    option.value = value
    option.text = label
    select.append(option)
    this.$stelectController.setValueExternally(value)
  }

  /**
   * Brute force modal template and append ?stelect_id=<stelect_id>
   * to the turbo-frame's src.
   */
  addStelectIdToTurboFrameSrc() {
    const turboFrameSrcRegex = /<turbo-frame.*?src\s*=\s*"(.+?)".*?>/g
    const turboFrameSrcWithSelectId = (match, p1) => {
      return p1
        ? match.replace(p1, `${p1}?stelect_id=${this.element.id}`)
        : match
    }
    this.$modalTemplate.innerHTML = this.$modalTemplate.innerHTML.replace(
      turboFrameSrcRegex,
      turboFrameSrcWithSelectId,
    )
  }
}

/**
 * Pushes callback to the end of the call stack,
 * forcing it execute last.
 */
function onNextTick(callback) {
  return setTimeout(callback, 0)
}
