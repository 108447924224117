import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkboxAll', 'checkboxContainer']

  initialize() {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
    this.checkboxes = []
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.addEventListener('change', this.toggle)
    this.checkboxes = Array.from(
      this.checkboxContainerTarget.querySelectorAll('input[type=checkbox]'),
    )
    this.checkboxes.forEach((checkbox) =>
      checkbox.addEventListener('change', this.refresh),
    )
    this.refresh()
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return
    this.checkboxAllTarget.removeEventListener('change', this.toggle)
    this.checkboxes.forEach((checkbox) =>
      checkbox.removeEventListener('change', this.refresh),
    )
    this.checkboxes = []
  }

  toggle(e) {
    e.preventDefault()

    if (e.target.checked) {
      this.checkboxContainerTarget.hidden = true
    } else {
      this.checkboxContainerTarget.hidden = false
    }

    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })
  }

  refresh() {
    const checkboxesCount = this.checkboxes.length
    const checkboxesCheckedCount = this.checkboxes.filter(
      (checkbox) => checkbox.checked,
    ).length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }
}
