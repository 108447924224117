import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'preview', 'canvas', 'uploadButton']

  connect() {
    console.log('Image upload controller connected')
  }

  event() {
    if (this._event === undefined) {
      this._event = new CustomEvent('instant-uploaded', {
        bubbles: true,
        detail: { message: 'custom event toggled' },
      })
    }
    return this._event
  }

  previewImage(event) {
    const input = this.inputTarget
    const preview = this.previewTarget
    const canvas = this.canvasTarget

    const file = input.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      preview.src = reader.result
      preview.classList.remove('hidden')
      canvas.classList.add('hidden')
      preview.dispatchEvent(this.event())
      console.log('Image preview updated')
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      preview.src = ''
    }
  }

  async uploadImage(event) {
    event.preventDefault()
    const input = this.inputTarget
    const file = input.files[0]

    if (!file) {
      alert('Please select a file first')
      return
    }

    const response = await fetch('/s3-sign/')
    const data = await response.json()
    const { url } = data

    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })

    alert('Image uploaded successfully')
  }
}
