import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open() {
    if (!this.element.fullscreenElement) {
      this.element.requestFullscreen()
    } else {
      if (this.element.fullscreenElement) {
        this.element.exitFullscreen()
      }
    }
  }
}
