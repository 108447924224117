import { Controller } from '@hotwired/stimulus'
import { runWhenConditionsAreMet } from '../utils/runWhenConditionsAreMet'

export default class extends Controller {
  static targets = ['source']
  static values = {
    editorSelector: String,
    editorOptions: Object,
  }

  connect() {
    runWhenConditionsAreMet(this.initEditor.bind(this), {
      conditionsCallback: () =>
        !!window.draftail && !!window.draftail.initEditor,
    })
  }

  initEditor() {
    window.draftail.initEditor(
      this.editorSelectorValue,
      this.editorOptionsValue,
      document.currentScript,
    )
  }
}
