import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['forms', 'template', 'addform']

  static values = {
    formPrefix: String,
    formSelector: String,
  }

  get totalFormsCount() {
    return this.__getManagementFormValue('TOTAL_FORMS')
  }

  get maxNumFormsLimit() {
    return this.__getManagementFormValue('MAX_NUM_FORMS')
  }

  get activeFormCount() {
    return this.totalFormsCount - this.deletedFormsCount
  }

  get deletedFormsCount() {
    return this.$forms.filter((f) =>
      f.hasAttribute('data-formset-form-deleted'),
    ).length
  }

  get hasReachedMaxForms() {
    const maxForms = Number.parseInt(this.maxNumFormsLimit.value, 10) || 1000
    return this.activeFormCount >= maxForms
  }

  get $sortableController() {
    return this.element.querySelector('[data-controller="sortable"]')
  }

  get $totalForms() {
    return this.__getManagementFormElement('TOTAL_FORMS')
  }

  get $addFormButton() {
    return this.addFormTarget
  }

  get $forms() {
    return Array.from(this.element.querySelectorAll(this.formSelector))
  }

  connect() {
    this.formSelector = this.formSelectorValue || '[data-formset-form]'
    this.toggleAddFormVisibility()
    this.element.addEventListener('change', this.handleChange.bind(this))
  }

  isDeleteCheckbox(el) {
    return el.matches("input[name*='-DELETE']")
  }

  getFormByInput(inputEl) {
    return inputEl.closest(this.formSelector)
  }

  handleChange(e) {
    if (this.isDeleteCheckbox(e.target) && e.target.checked) {
      this.removeForm(this.getFormByInput(e.target))
    }
  }

  toggleAddFormVisibility() {
    if (this.$addFormButton) {
      this.$addFormButton.setAttribute('hidden', this.hasReachedMaxForms)
    }
  }

  addForm(e) {
    e.preventDefault()
    const newIndex = this.totalFormsCount
    this.$totalForms.value = newIndex + 1
    const newForm = formFactory(this.templateTarget, newIndex)
    this.formsTarget.appendChild(newForm)
    this.toggleAddFormVisibility()
    // If formset is sortable, update item's position index.
    this.$sortableController?.__updateItemPosition(newForm, newIndex)
  }

  removeForm(form) {
    form.setAttribute('data-formset-form-deleted', '')

    Array.from(form.querySelectorAll('*[required]')).forEach((input) => {
      input.required = false
    })

    Array.from(form.querySelectorAll('*[pattern]')).forEach((input) => {
      input.removeAttribute('pattern')
    })

    form.style.display = 'none'
    form.querySelector("input[name*='-DELETE']").checked = true
  }

  __getManagementFormValue(name) {
    return Number.parseInt(this.__getManagementFormElement(name).value, 10)
  }

  __getManagementFormElement(name) {
    return this.element.querySelector(
      `[name="${this.formPrefixValue}-${name}"]`,
    )
  }
}

function formFactory(template, index) {
  const clonedTemplate = template.cloneNode(true)
  clonedTemplate.innerHTML = clonedTemplate.innerHTML
    .replace(/__prefix__/g, index)
    .replace(/<\\\/script>/g, '</script>')
  return clonedTemplate.content.firstElementChild
}
