import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['form']
  static values = {
    debounceTime: { type: Number, default: 300 },
  }

  connect() {
    this.formTarget.addEventListener(
      'turbo:submit-start',
      this.updateUrl.bind(this),
    )
    this.debouncedSubmit = debounce(
      this.submitForm.bind(this),
      this.debounceTimeValue,
    )

    // Add event listeners for form inputs
    this.formTarget
      .querySelectorAll('input, select, textarea')
      .forEach((element) => {
        element.addEventListener('input', this.autoSubmit.bind(this))
        element.addEventListener('change', this.autoSubmit.bind(this))
      })
  }

  updateUrl(event) {
    try {
      const url = new URL(window.location)
      const formData = new FormData(this.formTarget)
      const params = new URLSearchParams()

      // Only add non-empty parameters
      for (const [key, value] of formData.entries()) {
        if (value.trim() !== '') {
          params.append(key, value)
        }
      }

      url.search = params.toString()
      this.pushUrl(url)
    } catch (error) {
      console.error('Error updating URL:', error)
    }
  }

  updatePagination(event) {
    event.preventDefault()
    try {
      const url = new URL(event.currentTarget.href)
      Turbo.visit(url.href, { action: 'replace' })
    } catch (error) {
      console.error('Error updating pagination:', error)
    }
  }

  pushUrl(url) {
    Turbo.navigator.history.push(url)
  }

  autoSubmit(event) {
    this.debouncedSubmit()
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }
}
