/**
 * Runs a callback when conditions defined in conditionsCall back are met.
 *
 * @param callback - function to run when conditions are met;
 * @param conditionsCallback - a function that defines conditions and should return true or false;
 * @param retryTimeout - how much time to wait between checks;
 * @param maxWaitTime - how long to check before failing;
 */
export function runWhenConditionsAreMet(
  callback,
  { conditionsCallback, retryTimeout = 300, maxWaitTime = 3000 },
) {
  const startTime = +new Date()
  const isMaxWaitTimeExceeded = (currentTime) =>
    currentTime - startTime > maxWaitTime

  const interval = setInterval(() => {
    if (conditionsCallback()) {
      // Clear interval
      clearInterval(interval)
      callback()
      return
    }

    // Cancel and throw an error.
    if (isMaxWaitTimeExceeded(+new Date())) {
      clearInterval(interval)
      // Run callback to make it fail and throw an error.
      callback()
    }
  }, retryTimeout)
}
