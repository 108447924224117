import { Controller } from '@hotwired/stimulus'
import { smartFetch } from '../utils/smartFetch'

const OPTIONS_STATUS = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

const FOCUSED_STYLES = ['bg-blue-300']

export default class extends Controller {
  static targets = [
    'select',
    'selectedValue',
    'dropdown',
    'search',
    'listbox',
    'status',
  ]

  static values = {
    url: String,
    expanded: Boolean,
    emptyOptionLabel: String,
  }

  initialize() {
    this.connected = false
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.handleListboxFocus = this.handleListboxFocus.bind(this)
    this.handleDropdownKeydown = this.handleDropdownKeydown.bind(this)
    this.handleClickout = this.handleClickout.bind(this)
    this.emptyOptionLabel = this.emptyOptionLabelValue || 'Select option'
  }

  connect() {
    // Prevent multiple builds of the same instance.
    if (this.connected) {
      return
    }
    this._optionsStatus = OPTIONS_STATUS.IDLE

    // Every stelect needs a unique id to be used
    // with dom attributes.

    this.element.id = `stelect-${this.selectTarget.name}`
    this.uuid = this.element.id

    // Add custom select input that is visible instead of native select.
    this.element.append(
      renderInput({
        label: this.labelValue,
        value: this.value,
        uuid: this.uuid,
        emptyOption: this.emptyOptionLabel,
      }),
    )

    this.element.setValueExternally = this.setValueExternally.bind(this)

    // Hide native select.
    this.selectTarget.style.display = 'none'

    // Adds stelectCollapse method to the dom element, so it can
    // be closed from other controllers. See collapseOpenDropdowns for example.
    this.element.stelectCollapse = this.collapseDropdown.bind(this)

    document.addEventListener('click', this.handleClickout)

    this.connected = true
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickout)
  }

  get isExpanded() {
    return this.expandedValue
  }

  get $listboxOptions() {
    return Array.from(this.listboxTarget.querySelectorAll('[role=option]'))
  }

  get $selectOptions() {
    return Array.from(this.selectTarget.selectedOptions)
  }

  get $selectedOption() {
    return this.listboxTarget.querySelector('[role=option][aria-selected=true]')
  }

  get $focusedOption() {
    return (
      this.listboxTarget.querySelector('[role=option][data-focused]') ||
      this.$selectedOption
    )
  }

  set $focusedOption(option) {
    Array.from(this.listboxTarget.querySelectorAll('[role=option]')).forEach(
      (option) => toggleOptionFocusState(option, false),
    )
    if (option) {
      toggleOptionFocusState(option, true)
      scrollIntoViewIfNeeded(this.listboxTarget, option)
    }
  }

  get value() {
    return this.selectTarget.value
  }

  set value(value) {
    this.selectTarget.value = value
    this.selectedValueTarget.innerText = this.labelValue
  }

  get optionsStatus() {
    return this._optionsStatus
  }

  set optionsStatus(status) {
    this._optionsStatus = status
    switch (status) {
      case OPTIONS_STATUS.SUCCESS:
        this.statusTarget.classList.add('hidden')
        this.statusTarget.setAttribute('hidden', '')
        this.statusTarget.setAttribute('aria-hidden', 'true')
        break
      case OPTIONS_STATUS.FETCHING:
        this.statusTarget.classList.remove('hidden')
        this.statusTarget.setAttribute('aria-hidden', 'false')
        break
    }
  }

  get labelValue() {
    return this.$selectOptions.map((o) => o.text).join(',')
  }

  setValueExternally(value) {
    this.value = value
  }

  toggle() {
    this.isExpanded ? this.collapseDropdown() : this.expandDropdown()
  }

  async expandDropdown() {
    collapseOpenDropdowns()
    const currentValue = this.value
    this.dropdownTarget.removeAttribute('hidden')
    toggleDropdownVisibility(this.listboxTarget, true)
    this.expandedValue = true
    await this.populateOptions()
    this.highlightSelectedOption(currentValue)
    this.focusSearchInput()
  }

  collapseDropdown() {
    this.dropdownTarget.setAttribute('hidden', '')
    toggleDropdownVisibility(this.listboxTarget, false)
    this.expandedValue = false
  }

  async populateOptions(term) {
    try {
      this.optionsStatus = OPTIONS_STATUS.FETCHING
      const { results } = await smartFetch(
        term ? `${this.urlValue}?term=${term}` : this.urlValue,
      )
      if (results) {
        this.optionsStatus = OPTIONS_STATUS.SUCCESS
        this.clearAllOptions()

        const options = formatFetchedOptions(results, this.uuid)
        this.updateSelectOptions(options)
        this.updateListboxOptions(options)
      }

      this.optionsStatus = OPTIONS_STATUS.IDLE
    } catch (err) {
      console.error(err)
      this.optionsStatus = OPTIONS_STATUS.ERROR
    }
  }

  highlightSelectedOption(selectedValue) {
    Array.from(this.$listboxOptions).forEach((option) =>
      toggleOptionActiveState(option, selectedValue),
    )
  }

  focusSearchInput() {
    this.searchTarget.focus()
  }

  clearAllOptions() {
    this.listboxTarget.innerHTML = ''
    Array.from(this.selectTarget.options).forEach(
      (option) => option.value !== this.value && option.remove(),
    )
  }

  updateSelectOptions(options) {
    const emptyOption = {
      label: this.emptyOptionLabel,
      value: '',
    }

    const currentOption =
      (this.value || this.value === '0') && this.label
        ? [
            {
              value: this.value,
              label: this.label,
            },
          ]
        : []

    return Array.from(new Set([emptyOption, ...currentOption, ...options]))
      .map(renderSelectOption)
      .forEach((option) => attachOption(option, this.selectTarget))
  }

  updateListboxOptions(options) {
    return options
      .map(renderListboxOption)
      .forEach((option) => attachOption(option, this.listboxTarget))
  }

  selectListboxOption(option) {
    const value = option.dataset.value
    this.value = value
    this.highlightSelectedOption(value)
    this.collapseDropdown()
    this.searchTarget.value = ''
  }

  async handleSearchInput(e) {
    await this.populateOptions(e.target.value)
    this.highlightSelectedOption(e.target.value)
  }

  handleListboxFocus(e) {
    console.log(e)
  }

  handleListboxOptionClick(e) {
    console.log('clicks option')
    this.selectListboxOption(e.target)
  }

  handleClickout(e) {
    if (this.element === e.target || this.element.contains(e.target)) {
      return
    }
    this.collapseDropdown()
  }

  handleDropdownKeydown(e) {
    switch (e.key) {
      case 'Tab':
      case 'Escape':
        e.preventDefault()
        this.collapseDropdown()
        break
      case 'Enter':
        e.preventDefault()
        this.$focusedOption && this.selectListboxOption(this.$focusedOption)
        break

      case 'ArrowUp':
        {
          e.preventDefault()
          // Navigates up to the previous option.
          const item = getSiblingOption(
            this.$focusedOption,
            this.$listboxOptions,
            false,
          )
          if (item) {
            this.$focusedOption = item
          }
        }
        break

      case 'ArrowDown':
        {
          e.preventDefault()
          // Navigates down to the next option.
          const item = getSiblingOption(
            this.$focusedOption,
            this.$listboxOptions,
            true,
          )
          if (item) {
            this.$focusedOption = item
          }
        }
        break
    }
  }
}

/**
 * Collapses all open stelect dropdowns.
 */
function collapseOpenDropdowns() {
  const expandedDropdowns = document.querySelectorAll(
    '[data-controller="stelect"][data-stelect-expanded-value="true"]',
  )
  Array.from(expandedDropdowns).forEach((ctrl) => ctrl.stelectCollapse())
}

/**
 * Renders a custom stelect input.
 */
function renderInput({ label, value, uuid = '', emptyOption }) {
  const inputTemplate = document.createElement('template')
  inputTemplate.innerHTML = `
    <div class="relative">
      <button id="${createUniqueLabel(
        uuid,
        'selectedValue',
      )}" class="py-2 px-1 border border-gray-300 rounded-md bg-white text-left truncate w-full mr-1" type="button" data-stelect-target="selectedValue" data-action="click->stelect#toggle" aria-haspopup="true">${
        label || emptyOption
      }  <svg class="h-4 w-4 inline text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
        </svg>
      </button>
      <div class="absolute top-15 left-0 right-0 z-10 border-1 p-2 bg-gray-100 min-w-64 w-full" data-stelect-target="dropdown" data-action="keydown->stelect#handleDropdownKeydown" hidden>
          <input tabindex="0" class="w-full r" data-stelect-target="search" data-action="input->stelect#handleSearchInput" type="search" autofocus="on" autocapitalize="off" autocomplete="off" autocorrect="off" aria-autocomplete="list" aria-label="Search"  aria-controls="${createUniqueLabel(
            uuid,
            'listbox-results',
          )}" />
          <p class="hidden" data-stelect-target="status" aria-hidden="false" aria-live="polite">Loading options...</p>
          <ul class="overflow-y-auto max-h-64 " data-stelect-target="listbox" id="${createUniqueLabel(
            uuid,
            'listbox-results',
          )}" role="listbox" aria-expanded="false" aria-hidden="true" aria-labelledby="${createUniqueLabel(
            uuid,
            'selectedValue',
          )}" aria-activedescendant="${createUniqueLabel(uuid, `value-${value}`)}"></ul>
      </div>
    </div>`
  return inputTemplate.content
}

/**
 * Renders a stelect option.
 */
function renderListboxOption({ value, label, id }) {
  const optionTemplate = document.createElement('template')
  optionTemplate.innerHTML = `
    <li class="p-2 cursor-pointer border-b border-gray-100 hover:bg-blue-300" role="option" data-value="${value}" data-action="click->stelect#handleListboxOptionClick" id="${id}" tabindex="-1" aria-selected="false">
        ${label}
    </li>
  `
  return optionTemplate.content.firstElementChild.cloneNode(true)
}

/**
 * Renders a native select option.
 */
function renderSelectOption({ value, label }) {
  const optionTemplate = document.createElement('template')
  optionTemplate.innerHTML = `
    <option value="${value}">${label}</option>
  `
  return optionTemplate.content.firstElementChild.cloneNode(true)
}

/**
 * Converts Django's options response into native select option data format.
 */
function formatFetchedOptions(options, uuid) {
  return options.map(({ id, text }) => ({
    value: id,
    label: text,
    id: createUniqueLabel(uuid, id),
  }))
}

/**
 * Adds option to its parent container.
 */
function attachOption(option, parent) {
  parent.append(option)
}

/**
 * Creates unique label for a stelect value.
 */
function createUniqueLabel(uuid, label) {
  return `${uuid}-${label}`
}

/**
 * Scrolls element into container when it's not visible.
 */
function scrollIntoViewIfNeeded(container, target) {
  if (target.getBoundingClientRect().bottom > container.clientHeight) {
    target.scrollIntoView(false)
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView()
  }
}

/**
 * Switches an option's active/inactive state depending
 * on currently selected value.
 */
function toggleOptionActiveState(option, selectedValue) {
  const selectedOptionClasses = ['bg-yellow-300']
  const isSelected = String(option.dataset.value) === String(selectedValue)
  if (isSelected) {
    option.classList.add(...selectedOptionClasses)
    option.setAttribute('aria-selected', 'true')
    scrollIntoViewIfNeeded(option.parentElement, option)
  } else {
    option.classList.remove(...selectedOptionClasses)
    option.setAttribute('aria-selected', 'false')
  }
}

/**
 * Switches an option's active/inactive visual state.
 */
function toggleOptionFocusState(option, isFocused) {
  if (isFocused) {
    option.setAttribute('data-focused', '')
    option.classList.add(...FOCUSED_STYLES)
  } else {
    option.removeAttribute('data-focused')
    option.classList.remove(...FOCUSED_STYLES)
  }
}

/**
 * Returns next/previous sibling of an option.
 */
function getSiblingOption(option, options, isGetNextSibling = true) {
  const nextSibling = option
    ? option.nextSibling || options[options.length - 1]
    : options[0]
  const previousSibling = option ? option.previousSibling : options[0]
  return isGetNextSibling ? nextSibling : previousSibling
}

/**
 * Switches dropdown aria between visible/invisible.
 */
function toggleDropdownVisibility(el, visible = true) {
  el.setAttribute('aria-expanded', visible)
  el.setAttribute('aria-hidden', !visible)
}
