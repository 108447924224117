import { Controller } from '@hotwired/stimulus'

const ExerciseBlockFormat = {
  STRAIGHTSET: 0,
  SUPERSET: 1,
  CIRCUIT: 2,
  AMRAP: 3,
  FOR_TIME: 4,
  EMOM: 5,
  REST: 6,
}

export default class extends Controller {
  static targets = [
    'timer',
    'round',
    'label',
    'elapsed',
    'blockList',
    'totalRemaining',
    'workStatus',
  ]

  connect() {
    this.welcomeScreen = false
    this.startedAt = null
    this.isPaused = false
    this.pausedDuration = 0
    this.totalPaused = 0
    this.timerIndex = 0
    this.elapsedTime = 0

    const data = JSON.parse(
      this.element.querySelector("script[type='application/json']").textContent,
    )
    this.timers = []
    for (let i = 0; i < data.exerciseBlocks.length; i++) {
      const block = data.exerciseBlocks[i]

      if (
        block.format === ExerciseBlockFormat.SUPERSET ||
        block.format === ExerciseBlockFormat.CIRCUIT
      ) {
        for (let j = 1; j <= block.rounds; j++) {
          this.timers.push({
            exerciseBlockIndex: i,
            label: `${block.label} (work)`,
            duration: block.workingDuration,
            round: `${j}/${block.rounds}`,
            work: true,
          })
          this.timers.push({
            exerciseBlockIndex: i,
            label: `${block.label} (rest)`,
            duration: block.restDuration,
            round: `${j}/${block.rounds}`,
            work: false,
          })
        }
      } else if (
        block.format === ExerciseBlockFormat.STRAIGHTSET ||
        block.format === ExerciseBlockFormat.AMRAP ||
        block.format === ExerciseBlockFormat.FOR_TIME ||
        block.format === ExerciseBlockFormat.EMOM
      ) {
        this.timers.push({
          exerciseBlockIndex: i,
          label: block.label,
          duration: block.workingDuration,
          round: '-',
          work: true,
        })
      } else if (block.format === ExerciseBlockFormat.REST) {
        this.timers.push({
          exerciseBlockIndex: i,
          label: block.label,
          duration: block.restDuration,
          round: '-',
          work: false,
        })
      } else {
        throw new Error('unrecognized format')
      }
    }
    this.activeBlockClasses = (
      this.data.get('activeBlockClasses') || 'active'
    ).split(' ')
    this.totalTimer = 0

    for (const timer in this.timers) {
      this.totalTimer += this.timers[timer].duration
    }
    this.totalTimer = this.totalTimer * 1000
    this.showTotalRemaining()
  }

  display_time_string(time) {
    const minutes = Math.floor(time / 60000).toString()
    const seconds = Math.floor((time / 1000) % 60)
      .toString()
      .padStart(2, '0')
    const deciseconds = Math.floor((time / 100) % 10)
      .toString()
      .padEnd(1, '0')
    return `${minutes}:${seconds}.${deciseconds}`
  }

  showTotalRemaining() {
    this.totalTimeRemaining = this.totalTimer - this.elapsedTime
    if (this.totalTimeRemaining < 0) {
      this.totalTimeRemaining = 0
    }
    this.totalRemainingTarget.textContent = this.display_time_string(
      this.totalTimeRemaining,
    )
  }

  updateTime(timestamp) {
    this.intervalRemaining = Math.max(
      0,
      this.timers[this.timerIndex].duration * 1000 -
        Math.floor(timestamp - this.startedAt - this.pausedDuration),
    )
    this.elapsedTime = timestamp - this.totalStartedAt - this.totalPaused
    this.totalTimeRemaining = this.totalTimer - this.elapsedTime

    this.timerTarget.textContent = this.display_time_string(
      this.intervalRemaining,
    )
    this.roundTarget.textContent = this.timers[this.timerIndex].round
    if (this.timers[this.timerIndex].work === true) {
      this.workStatusTarget.textContent = 'WORK'
      this.workStatusTarget.classList.remove('text-red-600')
      this.workStatusTarget.classList.add('text-green-600')
    } else if (this.timers[this.timerIndex].work === false) {
      this.workStatusTarget.textContent = 'REST'
      this.workStatusTarget.classList.remove('text-green-600')
      this.workStatusTarget.classList.add('text-red-600')
    }

    // this.elapsedTarget.textContent = this.display_time_string(this.elapsedTime)
    this.labelTarget.textContent = this.timers[this.timerIndex].label

    if (this.intervalRemaining > 0 && !this.isPaused) {
      requestAnimationFrame(this.updateTime.bind(this))
      requestAnimationFrame(this.showTotalRemaining.bind(this))
    } else if (this.intervalRemaining <= 0) {
      this.runNextTimer(timestamp)
    }
  }

  runNextTimer(timestamp) {
    this.startedAt = timestamp
    this.totalPaused = this.totalPaused + this.pausedDuration
    this.pausedDuration = 0
    this.timerIndex++

    if (this.timerIndex === this.timers.length) {
      console.log('completed all timers')
      return
    }
    this.highlightExerciseBlock()
    requestAnimationFrame(this.updateTime.bind(this))
  }

  start() {
    if (this.startedAt !== null) {
      return
    }
    this.startedAt = performance.now()
    this.totalStartedAt = performance.now()

    this.highlightExerciseBlock()
    this.updateTime(this.startedAt)
  }

  pause() {
    if (this.isPaused) {
      return
    }
    this.isPaused = true
    this.pausedAt = performance.now()
  }

  resume() {
    if (!this.isPaused) {
      return
    }
    this.isPaused = false
    const timestamp = performance.now()
    this.pausedDuration += timestamp - this.pausedAt
    this.updateTime(timestamp)
  }

  highlightExerciseBlock() {
    const indexToHighlight = this.timers[this.timerIndex].exerciseBlockIndex
    for (let i = 0; i < this.blockListTarget.children.length; i++) {
      const item = this.blockListTarget.children[i]

      if (i === indexToHighlight) {
        item.classList.add(...this.activeBlockClasses)
        item.scrollIntoView(false)
      } else {
        item.classList.remove(...this.activeBlockClasses)
      }
    }
  }
}
