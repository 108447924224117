import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    const dataElement = document.getElementById(this.element.dataset.dataId)
    const data = JSON.parse(dataElement.textContent)
    const ctx = this.canvasTarget.getContext('2d')
    const lineColor = this.data.get('lineColor')

    const format = this.data.get('format') || 'default'

    let formatCallback = (value, index, values) => value
    if (format === 'duration') {
      formatCallback = (value, index, values) => {
        let string = ''
        if (value > 60) {
          string += `${Math.floor(value / 60)}mins ${Math.round(value % 60)}secs`
        } else {
          string += `${value.toFixed(2)}seconds`
        }
        return string
      }
    }

    const datasets = [
      {
        label: data.scores.label,
        data: data.scores.data,
        fill: false,
        pointBackgroundColor: lineColor,
        pointBorderColor: lineColor,
        borderColor: lineColor,
        borderWidth: 4,
      },
    ]
    if (data.goal) {
      datasets.push({
        label: 'Goal',
        data: data.goal.data,
        fill: false,
        pointBackgroundColor: '#FF2B6F',
        pointBorderColor: '#FF2B6F',
        borderColor: '#FF2B6F',
        borderWidth: 4,
        stepped: 'before',
      })
    }

    console.log(data.options.y.title.text)
    new Chart(ctx, {
      type: 'line',
      data: {
        datasets,
      },
      options: {
        legend: {
          labels: {
            fontColor: '#FFFFFF',
            fontSize: 16,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM d',
              },
            },
            ticks: {
              color: '#FFFFFF',
              fontSize: 14,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
          },
          y: {
            ticks: {
              color: '#FFFFFF',
              fontSize: 14,
              precision: 3,
              callback: formatCallback,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
            title: {
              display: true,
              text: data.options.y.title.text,
              color: '#FFFFFF',
              font: {
                size: 15,
              },
            },
          },
        },

        plugins: {
          legend: {
            labels: {
              color: '#FFFFFF',
              font: {
                size: 14,
              },
            },
          },
        },
      },
    })
  }
}
