import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    console.log('sorting')
    this.sortable = Sortable.create(document.getElementById('sortable'), {
      group: 'shared',
      animation: 150,
    })
  }
}
