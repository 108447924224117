// This is the scss entry file
import '../styles/index.css'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import * as Sentry from '@sentry/browser'

import {
  CategoryScale,
  Chart,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  RadarController,
  RadialLinearScale,
  TimeScale,
  Title,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import Bridge from '../controllers/turbo_native/bridge.js'

const sentryDsnMeta = document.querySelector(
  "meta[name='sharedgoals-sentry-dsn']",
)
if (sentryDsnMeta) {
  Sentry.init({
    dsn: sentryDsnMeta.content,
    release: 'sharedgoals',
    tracesSampleRate: 1.0,
  })
}

Chart.register(
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  TimeScale,
  Title,
)

const Turbo = require('@hotwired/turbo')
Turbo.start()

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

window.TurboNativeBridge = Bridge

console.log('🏋️‍♂️ SharedGoals online 🏋️‍♀️')
