import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    const dataElement = document.getElementById(this.element.dataset.dataId)
    const data = JSON.parse(dataElement.textContent)
    const ctx = this.canvasTarget.getContext('2d')
    const lineColor = this.data.get('lineColor')
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: data.label,
            data: data.data,
            fill: true,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            fillStyle: lineColor,
            borderColor: lineColor,
            borderWidth: 4,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM d',
              },
            },
            ticks: {
              color: '#FFFFFF',
              fontSize: 12,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
          },
          y: {
            ticks: {
              color: '#FFFFFF',
              fontSize: 12,
              suggestedMin: 30,
              suggestedMax: 100,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: '#FFFFFF',
              font: {
                size: 14,
              },
            },
          },
        },
      },
    })
  }
}
