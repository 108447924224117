import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'welcome',
    'thanks',
    'main',
    'welcomeduration',
    'welcometimer',
    'modalmain',
  ]

  connect() {
    console.log('ready to welcome!!')
  }

  hello() {
    this.welcomeTarget.classList.remove('hidden')
    this.mainTarget.classList.add('hidden')
  }

  confirm() {
    this.modalmainTarget.classList.add('hidden')
    this.startedAt = performance.now()
    this.welcomeTime(this.startedAt)
  }

  welcomeTime(timestamp) {
    this.remaining = Math.max(
      0,
      this.welcomedurationTarget.value * 60 * 1000 -
        Math.floor(timestamp - this.startedAt),
    )

    const minutes = Math.floor(this.remaining / 60000).toString()
    const seconds = Math.floor((this.remaining / 1000) % 60)
      .toString()
      .padStart(2, '0')
    this.welcometimerTarget.textContent = `${minutes}:${seconds}`

    if (this.remaining > 0 && !this.isPaused) {
      requestAnimationFrame(this.welcomeTime.bind(this))
    } else if (this.remaining <= 0) {
      console.log('exit welcome screen')
      this.exit()
    }
  }

  thankyou() {
    this.thanksTarget.classList.remove('hidden')
    this.mainTarget.classList.add('hidden')
  }

  exit() {
    this.remaining = 0
    this.thanksTarget.classList.add('hidden')
    this.welcomeTarget.classList.add('hidden')
    this.mainTarget.classList.remove('hidden')
    this.modalmainTarget.classList.remove('hidden')
  }
}
