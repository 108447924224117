import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    const dataElement = document.getElementById(this.element.dataset.dataId)
    const chartData = JSON.parse(dataElement.textContent)

    const ctx = this.canvasTarget.getContext('2d')

    const format = this.data.get('format') || 'default'

    let formatCallback = (value, index, values) => value
    if (format === 'duration') {
      formatCallback = (value, index, values) => {
        let string = ''
        if (value > 60) {
          string += `${Math.round(value / 60)}mins ${Math.round(value % 60)}secs`
        } else {
          string += `${value.toFixed(2)}seconds`
        }
        return string
      }
    }

    function getRandomColor() {
      const letters = '0123456789ABCDEF'.split('')
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }

    const datasets = []
    for (const sourceDataSet of chartData.dataSets) {
      const randomColor = getRandomColor()
      datasets.push({
        label: sourceDataSet.label,
        data: sourceDataSet.data,
        fill: false,
        pointBackgroundColor: randomColor,
        pointBorderColor: randomColor,
        borderColor: randomColor,
        borderWidth: 4,
      })
    }
    new Chart(ctx, {
      type: 'line',
      data: {
        datasets,
      },
      options: {
        legend: {
          labels: {
            fontColor: '#FFFFFF',
            fontSize: 16,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM d',
              },
            },
            ticks: {
              color: '#FFFFFF',
              fontSize: 14,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
          },
          y: {
            ticks: {
              color: '#FFFFFF',
              fontSize: 14,
              callback: formatCallback,
            },
            gridLines: {
              color: '#FFFFFF',
              lineWidth: 1,
            },
            title: {
              display: true,
              text: chartData.options.y.title.text,
              color: '#FFFFFF',
              font: {
                size: 15,
              },
            },
          },
        },

        plugins: {
          legend: {
            labels: {
              color: '#FFFFFF',
              font: {
                size: 14,
              },
            },
          },
        },
      },
    })
  }
}
