import { Controller } from '@hotwired/stimulus'

/**
 * Controls modal and a trigger that launches modal.
 *
 * Please note that the body of a modal is wrapped into <template> tag.
 *
 * <div data-controller="modal-trigger" data-modal-allow-background-close="true">
 *   <button type="button" data-action="click->modal-trigger#open" class="bg-blue-500 hover:bg-blue-700">+</button>
 *
 *   <template data-modal-trigger-target="template">
 *     <div id="turbo-modal" data-controller="modal" data-modal-allow-background-close="true"
 *          data-modal-autodestroyable-value="true" data-modal-open-value="true">
 *       <div data-modal-target="container"
 *            data-action="click->modal#closeBackground keyup@window->modal#closeWithKeyboard"
 *            class="hidden animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center"
 *            style="z-index: 9999;">
 *         <div class="max-h-screen w-full max-w-lg relative">
 *           <div class="m-1 bg-white rounded shadow">
 *             <div class="p-8" data-modal-target="content">
 *               MODAL CONTENT, HERE WE GO!
 *             </div>
 *           </div>
 *         </div>
 *       </div>
 *     </div>
 *   </template>
 * </div>
 */
export default class extends Controller {
  static targets = ['template']
  static values = {
    url: String,
  }

  open() {
    document.body.appendChild(this.createModal())
  }

  createModal() {
    return this.templateTarget.content.cloneNode(true)
  }
}
