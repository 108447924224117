/* demo from https://stimulusjs.org/handbook/building-something-real */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.urlValue)
  }
}
